import { CrudPathProps } from "routes/crud";
import { Client, Maybe, RetailAgency } from "types";
import { clientPremiumFinancePath } from "./client-premium-finance";

export interface ClientPremiumFinanceRetailAgencyPathProps
  extends CrudPathProps {
  retail_agency?: Maybe<RetailAgency>;
  retail_agency_id?: string;
}

export const clientPremiumFinanceRetailAgencyPath = (
  client: Client,
  opts?: ClientPremiumFinanceRetailAgencyPathProps
) => {
  const retail_agency_id = opts?.retail_agency?.id || opts?.retail_agency_id;

  const prefix = clientPremiumFinancePath(client);
  if (retail_agency_id) {
    if (opts?.update) {
      return `${prefix}retail-agency/${retail_agency_id}/edit/`;
    } else {
      return `${prefix}retail-agency/${retail_agency_id}/`;
    }
  } else {
    if (opts?.create) {
      return `${prefix}retail-agency/new/`;
    } else {
      return `${prefix}retail-agencies/`;
    }
  }
};
