import { Client, Invoice, Payment } from "types";

export interface ClientPayablesPathProps {
  invoice?: Invoice;
}

export const clientPayablesPath = (
  client: Client,
  opts?: ClientPayablesPathProps
) => {
  if (!client) {
    return "";
  }
  let url = "";
  if (opts?.invoice) {
    url = `/invoice/${opts.invoice.id}/`;
  } else {
    url = `/clients/${client.id}/bills/`;
  }
  return url;
};

export interface ClientPayablesPaymentInstrumentsPathProps {}

export const clientPayablesPaymentInstrumentsPath = (
  client: Client,
  opts?: ClientPayablesPaymentInstrumentsPathProps
) => {
  if (!client) {
    return "";
  }
  let url = "";
  url = `/clients/${client.id}/payment-instruments/`;
  return url;
};

export interface ClientPayablesPaymentHistoryPathProps {
  payment?: Payment;
}

export const clientPayablesPaymentHistoryPath = (
  client: Client,
  opts?: ClientPayablesPaymentHistoryPathProps
) => {
  if (!client) {
    return "";
  }
  let url = "";
  if (opts?.payment) {
    url = `/clients/${client.id}/payment-history/${opts?.payment?.id}/`;
  } else {
    url = `/clients/${client.id}/payment-history/`;
  }
  return url;
};
