import { Typography } from "@mui/material";
import { TypographyProps } from "./typography-props";

export default function H3({ children, sx, ...props }: TypographyProps) {
  return (
    <Typography
      variant="h3"
      sx={{
        fontWeight: props.bold ? 600 : 400,
        ...(sx || {}),
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}
