import { addQuery, CrudPathProps } from "routes/crud";
import { Client, Maybe, Payment } from "types";
import { clientMoneyInPath } from "./client-money-in";

export enum ClientPaymentPathTab {
  allocations = "allocations",
  receipt = "receipt",
  print = "print",
}

export interface ClientPaymentCrudPathProps extends CrudPathProps {
  payment?: Maybe<Payment>;
  payment_id?: string;
  tab?: ClientPaymentPathTab;
}

export const clientPaymentsPath = (
  client: Client,
  opts?: ClientPaymentCrudPathProps
) => {
  if (!client) {
    return "";
  }
  let url = "";
  const prefix = clientMoneyInPath(client);
  if (opts?.payment?.id || opts?.payment_id) {
    if (opts?.update) {
      url = `${prefix}payments/${opts.payment?.id || opts.payment_id}/edit/`;
    } else {
      if (opts?.tab) {
        url = `${prefix}payments/${opts.payment?.id || opts.payment_id}/${
          opts.tab
        }/`;
      } else {
        url = `${prefix}payments/${
          opts.payment?.id || opts.payment_id
        }/allocations/`;
      }
    }
  } else {
    if (opts?.create) {
      url = `${prefix}payments/new/`;
    } else {
      url = `${prefix}payments/`;
    }
  }
  if (opts?.query) {
    url = addQuery(url, opts.query);
  }
  return url;
};
