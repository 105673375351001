import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";
import { Client, Maybe } from "types";

const Root = styled("div")`
  img {
    // display: block;
    // height: 45px;
  }
`;

const FFLogo = ({ light, small }: { small?: boolean; light?: boolean }) => {
  return (
    <>
      <Box
        sx={{
          display: {
            xs: "none",
            sm: "block",
          },
        }}
      >
        <img
          alt="Functional Finance"
          src={`/logo-${small ? "mark" : "full"}-on-${
            light ? "light" : "dark"
          }.svg`}
        />
      </Box>
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "none",
          },
        }}
      >
        <img
          alt="Functional Finance"
          src={`/logo-${small ? "mark" : "full"}-on-${
            light ? "light" : "dark"
          }.svg`}
          style={{ height: "33px" }}
        />
      </Box>
    </>
  );
};

export default function Logo({
  endInsuredClient,
  style,
  light,
  small,
}: {
  endInsuredClient?: Maybe<Client>;
  style?: React.CSSProperties;
  light?: boolean;
  small?: boolean;
}) {
  const src = endInsuredClient?.branding?.logo_file_name || "ClientLogo.png";
  return (
    <Root>
      {endInsuredClient ? (
        <img alt={endInsuredClient.name} src={`/${src}`} style={style} />
      ) : (
        <FFLogo light={light} small={small} />
      )}
    </Root>
  );
}
