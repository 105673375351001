import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { Popover } from "element";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";

import { styled } from "@mui/material/styles";
import { useRouter } from "next/router";
import { ReactNode, useContext, useEffect, useState } from "react";
import { miniDrawerWidth } from "./NavMenu";
import { Maybe } from "types";
import NavContext from "context/NavContext";
import { Theme } from "@mui/system";

export const StyledListItem = styled(ListItem)(
  ({ theme }) => `
  
  color: ${theme.palette.grey[300]};
  .MuiListItemIcon-root {
    color: ${theme.palette.grey[300]};
  }
  .MuiListItemText-primary {
    font-weight: 600;
  }

  &.Mui-selected, &:hover {
    color: white;
    background: none;
    .MuiListItemIcon-root {
      color: ${theme.palette.primary.main}    
    }
    &:after {     
      display: block;
      content: "";
      position: absolute;
      right: 0px;
      top: 20px;
      width: 63px;
      height: 241px;
      background: url(/pin.png) no-repeat center right;
      transform: translateY(-50%);
    }
  }

`
);

export interface NavItemData {
  label: string;
  icon?: ReactNode;
  url?: string;
  urlPrefix?: string;
  items?: Array<NavItemData>;
}

interface NavItemProps {
  item: NavItemData;
  isSubItem?: boolean;
  disableInactiveLinks?: boolean;
}

const isSelected = (url: string, currentPath: string): boolean => {
  return currentPath.startsWith(url);
};

export default function NavItem({
  item,
  isSubItem,
  disableInactiveLinks,
}: NavItemProps) {
  const [anchorEl, setAnchorEl] = useState<Maybe<Element>>(null);
  const navContext = useContext(NavContext);
  const { open } = navContext;

  const handlePopoverOpen = (event: React.MouseEvent<Element>) => {
    if (!open) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const itemHovered = Boolean(anchorEl);

  const { label, url, urlPrefix, items, icon } = item;
  const hasItems = items ? items.length > 0 : false;

  const router = useRouter();
  const currentPath = router.asPath;
  const [showChildren, setShowChildren] = useState<boolean>(false);

  const selected = url && isSelected(urlPrefix || url, currentPath);

  useEffect(() => {
    const checkSelected = () => {
      if (selected) {
        setShowChildren(true);
      } else if (hasItems) {
        (items || []).forEach((item) => {
          if (item.url && isSelected(item.url, currentPath)) {
            setShowChildren(true);
          }
        });
      }
    };
    if (currentPath) {
      checkSelected();
    }
  }, [currentPath, hasItems, items, selected]);

  const classNames = [];
  if (hasItems) {
    classNames.push("nav-item-with-children");
  }
  if (isSubItem) {
    classNames.push("nav-item-is-child");
  }

  // When styling listItem, TS stops liking the componet and href props. Force the typing here.
  const extraProps: { [x: string]: any } = {
    component: "a",
    href: url || "#",
  };

  const disabled = !!(
    disableInactiveLinks &&
    extraProps.href === "#" &&
    (!hasItems || (items || []).filter((item) => !!item.url).length === 0)
  );

  return (
    <>
      <StyledListItem
        disabled={disabled}
        selected={!!selected}
        {...extraProps}
        className={classNames.join(" ")}
        onClick={() => setShowChildren(!showChildren)}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {icon && <ListItemIcon>{icon}</ListItemIcon>}
        {hasItems &&
          (showChildren ? <ArrowDropDownIcon /> : <ArrowRightIcon />)}
        <ListItemText>{label}</ListItemText>
        {!open && (
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handlePopoverClose}
            open={itemHovered}
            sx={{
              left: miniDrawerWidth,
              ".MuiBackdrop-root": {
                left: miniDrawerWidth,
              },
              ".MuiPaper-root": {
                left: "0 !important",
                borderRadius: `0 8px 8px 0`,
                background: (theme: Theme) => theme.palette.primary.main,
                color: "white",
                pt: 1.5,
                pb: 1.5,
                pl: 2,
                pr: 2,
                fontSize: "14px",
                fontWeight: 600,
              },
            }}
          >
            {label}
          </Popover>
        )}
      </StyledListItem>
      {hasItems && (
        <Collapse in={showChildren}>
          <List disablePadding>
            {(items || []).map((item) => {
              return (
                <NavItem
                  key={item.label}
                  item={item}
                  isSubItem
                  disableInactiveLinks={disableInactiveLinks}
                />
              );
            })}
          </List>
        </Collapse>
      )}
    </>
  );
}
