import { ReactNode } from "react";
import { Grid } from "@mui/material";

export interface GridContainerProps {
  spacing?: number;
  columnSpacing?: number;
  rowSpacing?: number;
  column?: boolean;
  children?: ReactNode;
  [x: string]: any;
}

export default function GridContainer({
  spacing,
  columnSpacing,
  rowSpacing,
  children,
  column,
  ...props
}: GridContainerProps) {
  const finalProps = { ...props };
  if (spacing != null) {
    finalProps.spacing = spacing;
  } else {
    finalProps.columnSpacing = columnSpacing == null ? 3 : columnSpacing;
    finalProps.rowSpacing = rowSpacing == null ? 2 : rowSpacing;
  }
  if (column) {
    finalProps.direction = "column";
  }
  return (
    <Grid container {...finalProps}>
      {children}
    </Grid>
  );
}
