import React from "react";

const defaultNavOpen: boolean = true;

export type NavContextType = {
  open: boolean;
  setOpen: (newVal: boolean) => void;
};

const NavContext = React.createContext({
  open: defaultNavOpen,
  //setUserId: (newUserId: Maybe<string>): void => {},
  setOpen: async (newVal: boolean): Promise<void> => {},
} as NavContextType);

export default NavContext;
