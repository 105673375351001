import { CrudPathProps } from "routes/crud";
import { Client, Maybe, PremiumFinanceContract } from "types";
import { clientPremiumFinancePath } from "./client-premium-finance";

export interface ClientPremiumFinanceContractPathProps extends CrudPathProps {
  premium_finance_contract?: Maybe<PremiumFinanceContract>;
  premium_finance_contract_id?: string;
}

export const clientPremiumFinanceContractPath = (
  client: Client,
  opts?: ClientPremiumFinanceContractPathProps
) => {
  const premium_finance_contract_id =
    opts?.premium_finance_contract?.id || opts?.premium_finance_contract_id;

  const prefix = clientPremiumFinancePath(client);
  if (premium_finance_contract_id) {
    return `${prefix}contracts/${premium_finance_contract_id}/`;
  } else {
    return `${prefix}contracts/`;
  }
};
