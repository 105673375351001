import {
  default as MUIDivider,
  DividerProps as MUIDividerProps,
} from "@mui/material/Divider";

interface DividerProps extends MUIDividerProps {
  dark?: boolean;
}

export default function Divider({ dark, ...props }: DividerProps) {
  return <MUIDivider light={!dark} {...props} />;
}
