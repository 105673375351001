import { Maybe } from "types";

export const formatCents = (cents: Maybe<number>) => {
  if (cents == null) {
    return "";
  }
  return formatDollars(cents / 100);
};

export const formatDollars = (dollars: Maybe<number | string>) => {
  if (dollars == null) {
    return "";
  }
  let dollarsNumber = dollars;
  if (typeof dollarsNumber === "string") {
    dollarsNumber = parseFloat(dollarsNumber);
    if (isNaN(dollarsNumber)) {
      return dollars as string;
    }
  }

  // Prevent "-0" values
  if (dollarsNumber === 0) {
    dollarsNumber = 0;
  }
  return Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(dollarsNumber);
};
