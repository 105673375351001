import { ReactNode, useState } from "react";
import { Dialog, GridContainer, Button } from "element";
import GridItem from "./GridItem";

interface ConfirmableProps {
  message: string;
  confirmLabel?: string;
  denyLabel?: string;
  onConfirm(): void;
  onDeny?: () => void;
  children(handleConfirm: () => void): ReactNode;
}

export default function Confirmable({
  message,
  confirmLabel,
  denyLabel,
  onConfirm,
  onDeny,
  children,
}: ConfirmableProps) {
  const [open, setOpen] = useState<boolean>(false);
  const handleConfirm = () => {
    setOpen(true);
  };

  return (
    <>
      {children(handleConfirm)}
      <Dialog title="Please Confirm" open={open} onClose={() => setOpen(false)}>
        <GridContainer column>
          <GridItem>{message}</GridItem>
          <GridItem>
            <GridContainer justifyContent="center">
              <GridItem>
                <Button
                  forward
                  onClick={() => {
                    if (onConfirm) {
                      onConfirm();
                    }
                    setOpen(false);
                  }}
                >
                  {confirmLabel || "Yes"}
                </Button>
              </GridItem>
              <GridItem>
                <Button
                  back
                  onClick={() => {
                    if (onDeny) {
                      onDeny();
                    }
                    setOpen(false);
                  }}
                >
                  {denyLabel || "No"}
                </Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Dialog>
    </>
  );
}
