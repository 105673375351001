import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { useState, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider, EmotionCache } from "@emotion/react";

import UserContext, { UserContextType } from "context/UserContext";
//import LocaleContext, { LocaleContextType } from 'context/LocaleContext';

import theme from "theme";
import createEmotionCache from "theme/createEmotionCache";
const clientSideEmotionCache = createEmotionCache();

import { Maybe, User, UserType } from "types";
interface FFAppProps extends AppProps {
  emotionCache?: EmotionCache;
}

import "../styles/globals.css";
import { identify, logout as backendLogout } from "requests/system/auth";
import ErrorBoundary from "element/ErrorBoundary";
import { Loading } from "element";
import { clientGet } from "requests/client";

import Script from "next/script";
import RequestErrorHandler from "element/RequestErrorHandler";
import ImpersonationConfirmation from "layouts/ImpersonationConfirmation";
import usePersistantState from "hooks/usePersistantState";
import NavContext, { NavContextType } from "context/NavContext";

function MyApp({
  Component,
  emotionCache = clientSideEmotionCache,
  pageProps,
}: FFAppProps) {
  const [user, setUser] = useState<Maybe<User>>(undefined);
  const [isNavOpen, setIsNavOpen] = usePersistantState<boolean>(
    "navOpen",
    true
  );

  // let storedUserId: Maybe<string> = null;
  // if (typeof(window)!=='undefined') {
  //   const storedUserIdString: Maybe<string> = window.localStorage.getItem(USER_ID_KEY);
  //   if (storedUserIdString) {
  //     storedUserId = storedUserIdString;
  //   }
  // }

  // const setUserFromId = async (newUserId: Maybe<string>) => {
  //   if (newUserId) {
  //     // It's always self?
  //     try {
  //       const newUser = (await identify()) as User;
  //       setUser(newUser);
  //     } catch (err) {
  //       // No current user
  //       setUser(null);
  //       console.error(err);
  //     }
  //   } else {
  //     setUser(null);
  //   }
  // }
  const loadUserFromSession = async () => {
    try {
      const userResp = await identify();
      if (userResp.user_type === UserType.client && userResp.client_id) {
        const clientResp = await clientGet({ id: userResp.client_id });
        userResp.client = clientResp;
      }
      setUser(userResp);
    } catch (err) {
      // No current user
      setUser(null);

      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    // TODO: store a user-updated-at data so even if user is present
    // in the state tree, check they're still logged in if it's been a minute.
    // e.g. if (!user || userNeedsRefresh)
    if (!user) {
      loadUserFromSession();
    }
  }, [user]);

  // useEffect(()=>{
  //   if (storedUserId && user?.id != storedUserId) {
  //     console.log("loading full user data")
  //     setUserFromId(storedUserId);
  //   }
  // }, [storedUserId])

  const baseUserContext: UserContextType = {
    user: user as Maybe<User>,
    //setUserId: setUserFromId,
    loadUserFromSession,
    logout: async () => {
      await backendLogout();
      setUser(null);
      //setUserFromId(null);
    },
  };

  const baseNavContext: NavContextType = {
    open: isNavOpen,
    setOpen: setIsNavOpen,
  };

  const isLoading = typeof user === "undefined";

  const router = useRouter();

  // If there is an impersonate query param, and we're an admin, show a dialog offering to change users
  const { impersonate } = router.query;
  const showImpersonationConfirmation =
    !isLoading && user?.user_type === UserType.admin && !!impersonate;
  const showComponent = !isLoading && !showImpersonationConfirmation;

  return (
    <ErrorBoundary>
      <Script type="text/javascript" src="/sardine-dev-init.js"></Script>
      <CacheProvider value={emotionCache}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            {/* <LocaleContext.Provider value={baseLocaleContext}> */}
            <UserContext.Provider value={baseUserContext}>
              <NavContext.Provider value={baseNavContext}>
                <Head>
                  <title>Functional Finance</title>
                  <meta name="description" content="Functional Finance" />
                </Head>
                {isLoading && <Loading />}
                {showImpersonationConfirmation && <ImpersonationConfirmation />}
                {showComponent && <Component {...pageProps} />}
                <RequestErrorHandler />
              </NavContext.Provider>
            </UserContext.Provider>
          </LocalizationProvider>
        </ThemeProvider>
      </CacheProvider>
    </ErrorBoundary>
  );
}
export default MyApp;
