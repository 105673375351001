import * as _ from "lodash";
import { createTheme, darken, lighten } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material";
import { default as defaultPalette, grey } from "./palette";
import { ClientBranding } from "types";

export const BORDER_RADIUS = 8;
export const INPUT_RADIUS = 4;
export const BUTTON_RADIUS = 4;
export const APP_BAR_HEIGHT = 89;
export const FONT_FAMILY = "Inter, sans-serif";
export const BUTTON_BOX_SHADOW =
  "0px 5px 12px rgba(131, 217, 255, 0.1), 0px 5px 12px rgba(127, 86, 217, 0.16)";

export const TOOLTIP_SHADOW = "0px 7px 23px rgba(95, 87, 118, 0.52)";

export const buildTheme = (
  buildPalette: (defaultPalette: PaletteOptions) => PaletteOptions
) => {
  const palette = buildPalette(defaultPalette);
  const baseTheme = createTheme({ palette });

  return createTheme({
    palette,
    shape: {
      borderRadius: INPUT_RADIUS,
    },
    typography: {
      fontSize: 14,
      fontFamily: FONT_FAMILY,
      h1: {
        fontSize: "36px",
        lineHeight: "43.5px",
        fontWeight: 600,
      },
      h2: {
        fontSize: "26px",
        lineHeight: "31.5px",
        fontWeight: 600,
      },
      h3: {
        fontSize: "20px",
        lineHeight: "24.2px",
        fontWeight: 600,
      },
      h4: {
        fontSize: "16px",
        lineHeight: "24px",
      },
      overline: {
        color: grey[200],
        fontSize: "11px",
        lineHeight: "13.31px",
        fontWeight: 600,
        textTransform: "uppercase",
      },
      body1: {
        fontSize: "14px",
        lineHeight: "20px",
      },
      body2: {
        fontSize: "12px",
        lineHeight: "18px",
        //color: baseTheme.palette.text.disabled,
      },
      subtitle1: {
        fontSize: "12px",
        lineHeight: "16px",
        //color: baseTheme.palette.text.disabled,
      },
      subtitle2: {
        fontSize: "10px",
        lineHeight: "14px",
        //color: baseTheme.palette.text.disabled,
      },
    },
    components: {
      MuiDivider: {
        styleOverrides: {
          root: {
            borderColor: baseTheme.palette.grey[600],
            "&.MuiDivider-light": {
              borderColor: baseTheme.palette.divider,
            },
          },
        },
      },
      MuiDrawer: {
        styleOverrides: {
          // root: {
          //   color: "white",
          //   ".MuiIconButton-root, .MuiListItemIcon-root": {
          //     color: "white",
          //   },
          //   ".MuiListItemIcon-root": {
          //     minWidth: "32px",
          //   },
          // },
          // paper: {
          //   background: baseTheme.palette.primary.darkest,
          //   color: "white",
          // },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: baseTheme.palette.appBarBackground.main,
            boxShadow: "none",
            borderBottomWidth: 1,
            borderBottomStyle: "solid",
            borderBottomColor: baseTheme.palette.divider,
            height: APP_BAR_HEIGHT,
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: {
          html: { height: "100%" },
          "#__next": { height: "100%" },
          body: {
            height: "100%",
            margin: 0,
            padding: 0,
            fontFamily: FONT_FAMILY,
            backgroundColor: "white",
            "@media print": {
              backgroundColor: "white",
              color: "black",
            },
          },
          ul: {
            paddingLeft: baseTheme.spacing(3),
          },
          ".MuiTextField-root .MuiInputBase-multiline": {
            padding: 0,
            ".MuiInputBase-input": {
              padding: "16.5px 14px",
            },
          },
        },
      },
      // Commented lines below is experimenting with shrink label stylings
      // MuiOutlinedInput: {
      //   styleOverrides: {
      //     notchedOutline: {
      //       'legend span': {
      //         display: 'none'
      //       }
      //     }
      //   }
      // },
      // MuiInputLabel: {
      //   styleOverrides: {
      //     root: {
      //       transform: 'translate(14px, 32px) scale(1)'
      //     },
      //     shrink: {
      //       transform: 'translate('+INPUT_RADIUS+'px, -8px) scale(1)',

      //     }
      //   }
      // },
      // MuiTextField: {
      //   styleOverrides: {
      //     root: {
      //       padding: baseTheme.spacing(2) + ' 0 0 0',

      //     }
      //   }
      // },
      MuiInputBase: {
        styleOverrides: {
          root: {
            background: baseTheme.palette.background.paper,
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            paddingBottom: "2px",
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 0,
            color: baseTheme.palette.text.primary,
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            paddingLeft: BORDER_RADIUS / 2,
            paddingRight: BORDER_RADIUS / 2,
          },
          // filled: {
          //   background: baseTheme.palette.primaryLight.main,
          //   color: baseTheme.palette.primary.main
          // }
        },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            //background: "linear-gradient(98.24deg, rgba(35, 11, 49, 0.8) 7.89%, rgba(23, 12, 61, 0.8) 57.01%, rgba(23, 11, 49, 0.8) 101.27%), #FFFFFF",
            //boxShadow: "unset",
            //border: `1px solid ${baseTheme.palette.divider}`,
            //color: 'white',
            //textAlign: 'center',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: BORDER_RADIUS,
            padding: BORDER_RADIUS,
          },
        },
      },
      MuiContainer: {
        defaultProps: {
          //maxWidth: "lg",
          //fixed: true
        },
        styleOverrides: {
          root: {
            [baseTheme.breakpoints.up("md")]: {
              padding: "0 20px",
            },
            [baseTheme.breakpoints.up("lg")]: {
              padding: "0 70px",
            },
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            "@media print": {
              marginBottom: "1em",
            },
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            //background: baseTheme.palette.backgroundHeader,
            "& .MuiTableRow-root": {
              backgroundColor: "transparent",
              "&:nth-of-type(even), &:nth-of-type(odd)": {
                backgroundColor: "transparent",
              },
            },
            "& .MuiTableCell-root": {
              fontWeight: 600,
            },
            "@media print": {
              "& .MuiTableCell-root": {
                border: 0,
                background: "white !important",
              },
            },
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            "& .MuiTableRow-root": {
              backgroundColor: "transparent",
              "&:nth-of-type(even), &:nth-of-type(odd)": {
                backgroundColor: "transparent",
              },
            },

            border: 0,
            "& .MuiTableCell-root": {
              borderBottom: 0,
              fontWeight: 600,
            },
            "@media print": {
              borderTop: "1px solid " + baseTheme.palette.divider,
              "& .MuiTableCell-root": {
                border: 0,
                background: "white !important",
              },
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          sizeMedium: {
            fontSize: "14px",
            lineHeight: "20px",
            fontWeight: 400,
            padding: baseTheme.spacing(2),
            "&:first-of-type": {
              paddingLeft: baseTheme.spacing(2),
            },
            "&:last-child": {
              paddingRight: baseTheme.spacing(2),
            },
            "@media print": {
              WebkitPrintColorAdjust: "exact !important",
              padding: baseTheme.spacing(0.5),
            },
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            padding: "0 8px",
            "&:nth-of-type(odd)": {
              backgroundColor: grey[25],
            },
            "&:nth-of-type(even)": {
              backgroundColor: "transparent",
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            ".MuiGrid-item": {
              maxWidth: "100%",
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: BUTTON_RADIUS,
            padding: "8px 16px",
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            //fontWeight: 500,
            [baseTheme.breakpoints.down("sm")]: {
              //minWidth: 270
              width: "100%",
              minHeight: "36px",
            },
            opacity: 0.8,
            border: `2px solid rgba(179, 180, 193, 0.7)`,
            fontSize: "14px",
            lineHeight: "1em",
            ".MuiButton-iconSizeMedium svg": {
              fontSize: "14px",
            },
            "&.MuiButton-sizeLarge": {
              minHeight: "44px",
              fontSize: "16px",
              [baseTheme.breakpoints.down("sm")]: {
                minHeight: "44px",
              },
            },
            ".MuiButton-iconSizeLarge svg": {
              fontSize: "16px",
            },

            "&.MuiButton-sizeSmall": {
              fontSize: "11px",
              minHeight: "32px",
              padding: "11px 8px",

              border: "none",
              fontWeight: 600,
              [baseTheme.breakpoints.down("sm")]: {
                minHeight: "32px",
              },
              "&.Mui-disabled": {
                border: "none",
              },
              "&:hover": {
                border: "none",
                "&.back": {
                  boxShadow: "none",
                },
                "&.table-cta": {
                  boxShadow: BUTTON_BOX_SHADOW,
                },
                color: baseTheme.palette.primary.main,
              },
              "&:active": {
                border: "none",
                boxShadow: "none",
                background: "none",
                color: baseTheme.palette.primary.darker,
                ".MuiButton-iconSizeSmall": {
                  color: baseTheme.palette.primary.main,
                },
                "&.table-cta": {
                  background: baseTheme.palette.primary.lighter,
                },
              },
              ".MuiButton-iconSizeSmall svg": {
                fontSize: "11px",
              },
            },
          },
          outlined: {
            backgroundColor: "white",
            color: baseTheme.palette.text.primary,
            "&:hover": {
              backgroundColor: "white",
              opacity: 1,
              color: baseTheme.palette.text.primary,
              border: `2px solid ${baseTheme.palette.primary.main}`,
              boxShadow: BUTTON_BOX_SHADOW,
              ".MuiButton-iconSizeLarge, .MuiButton-iconSizeMedium, .MuiButton-iconSizeSmall":
                {
                  color: baseTheme.palette.primary.main,
                },
            },
            "&:active": {
              opacity: 1,

              backgroundColor: baseTheme.palette.primary.dark,
              border: `2px solid ${baseTheme.palette.primary.main}`,
              color: "white",
              ".MuiButton-iconSizeLarge, .MuiButton-iconSizeMedium, .MuiButton-iconSizeSmall":
                {
                  color: "white",
                },
            },
            "&.Mui-disabled": {
              border: "1px solid rgba(179, 180, 193, 0.7);",
              opacity: 0.3,
              backgroundColor: "white",
              color: baseTheme.palette.text.primary,
            },
          },
          contained: {
            backgroundColor: baseTheme.palette.primary.main,
            border: `2px solid ${baseTheme.palette.primary.main}`,
            boxShadow: "none",
            "&:hover": {
              boxShadow: "none",
              backgroundColor: baseTheme.palette.primary.darker,
              border: `2px solid ${baseTheme.palette.primary.darker}`,
            },
            "&:active": {
              boxShadow: "none",
              backgroundColor: `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), ${baseTheme.palette.primary.darker};`,
              border: `2px solid ${baseTheme.palette.primary.darker}`,
            },
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          root: {},
          toolbar: {
            paddingLeft: "2px !important",
            paddingRight: "2px !important",
          },
        },
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            "&::before": {
              backgroundColor: baseTheme.palette.background.paper,
              boxShadow: TOOLTIP_SHADOW,
            },
          },
          tooltip: {
            background: baseTheme.palette.background.paper,
            color: baseTheme.palette.text.primary,
            boxShadow: TOOLTIP_SHADOW,
            borderRadius: BUTTON_RADIUS,
            fontSize: "12px",
            lineHeight: "150%",
            padding: baseTheme.spacing(1),
          },
        },
      },
    },
  });
};

export const buildClientTheme = (branding: ClientBranding) => {
  const primaryColor = branding?.primary_color || "#103A49";
  const secondaryColor = branding?.secondary_color || "#F95A00";
  const primaryLightColor = primaryColor[200];
  const fontFamily = branding?.font_family;

  const theme = buildTheme((defaultPalette: PaletteOptions) => {
    return {
      ...defaultPalette,
      primary: {
        lightest: lighten(primaryColor, 0.9),
        lighter: lighten(primaryColor, 0.7),
        light: lighten(primaryColor, 0.3),
        main: primaryColor,
        dark: darken(primaryColor, 0.1),
        darker: darken(primaryColor, 0.3),
        darkest: darken(primaryColor, 0.5),
      },
      primaryLight: {
        dark: lighten(primaryColor, 0.5),
        main: primaryLightColor,
        light: lighten(primaryColor, 0.7),
      },
      secondary: secondaryColor
        ? {
            dark: darken(secondaryColor, 0.5),
            main: secondaryColor,
            light: lighten(secondaryColor, 0.5),
          }
        : defaultPalette.secondary,
      secondaryLight: branding?.secondary_color
        ? {
            main: branding?.secondary_color,
          }
        : defaultPalette.secondaryLight,
      appBarBackground: {
        main: primaryColor,
      },
      backgroundGradient: primaryColor
        ? `linear-gradient(-15deg, ${primaryLightColor} 0%,  ${primaryColor} 80%)`
        : defaultPalette.backgroundGradient,
    };
  });

  if (branding.mui_theme) {
    _.merge(theme, branding.mui_theme);
  }
  if (fontFamily) {
    return _.cloneDeepWith(theme, (value) =>
      value === FONT_FAMILY ? fontFamily : undefined
    );
  }
  return theme;
};

export default buildTheme((defaultPalette) => defaultPalette);
