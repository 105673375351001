import { ReactNode } from "react";
import MUIChip from "@mui/material/Chip";
import { Theme } from "@mui/material";
import { SystemStyleObject } from "@mui/system";

interface ChipParams {
  children?: ReactNode;
  color?: "primary" | "secondary" | "success" | "warning" | "error" | "default";
  [x: string]: any;
}

const getColors = (
  theme: Theme,
  color: "primary" | "secondary" | "success" | "warning" | "error" | "default"
) => {
  if (color === "primary") {
    return {
      color: theme.palette.primary.dark,
      hoverColor: "white",
      activeColor: "white",
      background: theme.palette.primary.lighter,
      hoverBackground: theme.palette.primary.main,
      activeBackground: theme.palette.primary.dark,
    };
  } else if (color === "default") {
    return {
      color: theme.palette.grey[600],
      hoverColor: "white",
      activeColor: "white",
      background: theme.palette.grey[50],
      hoverBackground: theme.palette.grey[600],
      activeBackground: theme.palette.grey[800],
    };
  } else {
    return {
      color: theme.palette[color]?.dark,
      hoverColor: "white",
      activeColor: "white",
      background: theme.palette[color]?.light,
      hoverBackground: theme.palette[color]?.main,
      activeBackground: theme.palette[color]?.dark,
    };
  }
};

export default function Chip({
  children,
  color = "primary",
  ...props
}: ChipParams) {
  const getSx = (theme: Theme): SystemStyleObject<Theme> => {
    const colors = getColors(theme, color);
    return {
      fontWeight: 600,
      color: colors.color,
      background: colors.background,
      "&:hover, &:active": props.onClick
        ? {
            color: colors.hoverColor,
            background: colors.hoverBackground,
          }
        : {},
      "&:active": props.onClick
        ? {
            color: colors.activeColor,
            background: colors.activeBackground,
          }
        : {},
    };
  };

  return (
    <MUIChip
      sx={(theme: Theme) => {
        return getSx(theme);
      }}
      label={children}
      {...props}
      size="small"
    />
  );
}
