import { BilledEntity } from "dsl/billed-entity";

export interface AccountCheckoutPathParams {
  billedEntity?: BilledEntity;
  billedEntityId?: string;
}

export const accountCheckoutPath = ({
  billedEntity,
  billedEntityId,
}: AccountCheckoutPathParams) => {
  return `/checkout/account/${billedEntity?.id || billedEntityId}/`;
};
