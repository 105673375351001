import { ReactNode } from "react";
import Chip from "./Chip";
import { SxProps } from "@mui/material";
import { Theme } from "@mui/material";
import { grey, white } from "theme/palette";
import { BORDER_RADIUS } from "theme";

interface FilterTabParams {
  active?: boolean;
  children?: ReactNode;
  min?: boolean;
  [x: string]: any;
}

export default function FilterTab({
  active,
  children,
  min,
  ...props
}: FilterTabParams) {
  let padding: string = "10px 6px";
  if (active) {
    padding = "10px 6px 10px 18px";
  }
  if (min) {
    padding = "3px";
  }

  const sx: SxProps<Theme> = {
    background: (theme: Theme) => {
      return active ? theme.palette.primary.lighter || "inherit" : "none";
    },
    borderRadius: "8px",
    color: (theme: Theme) => (active ? theme.palette.primary.main : grey[200]),
    padding,
    ".MuiChip-label": {
      padding: min ? "3px" : undefined,
    },
    "&:hover": {
      background: grey[50],
    },
    "&:active": {
      background: (theme: Theme) => theme.palette.primary.main,
      color: white,
    },
    "&:before":
      active && !min
        ? {
            display: "block",
            content: '""',
            position: "absolute",
            top: BORDER_RADIUS,
            left: BORDER_RADIUS,
            background: (theme: Theme) => theme.palette.primary.main,
            width: BORDER_RADIUS,
            height: BORDER_RADIUS,
            borderRadius: BORDER_RADIUS,
          }
        : {},
  };

  return <Chip sx={sx} size="small" label={children} {...props} />;
}
