import React, { ReactNode, useContext } from "react";

import {
  Drawer,
  IconButton,
  List,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { styled } from "@mui/material/styles";

import { Box } from "@mui/system";
import { Theme, CSSObject } from "@mui/material/styles";

import NavItem, { NavItemData, StyledListItem } from "./NavItem";
import CodeIcon from "icon/expand-collapse-icon";
import GridContainer from "element/GridContainer";
import GridItem from "element/GridItem";
import Logo from "component/Logo";
import UserContext from "context/UserContext";
import { useRouter } from "next/router";

import * as routes from "routes";
import { Client, UserType } from "types";
import { clientPath } from "routes";
import LogoutIcon from "icon/logout-icon";
import ConfigurationIcon from "icon/configuration-icon";
import Link from "element/Link";
import { Overline } from "element/typography";

export const drawerWidth = 260;
export const miniDrawerWidth = 60;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  ".MuiListItem-root": {
    padding: "12px 30px",
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: miniDrawerWidth,
  [theme.breakpoints.up("sm")]: {
    width: miniDrawerWidth,
  },
  ".MuiListItem-root": {
    padding: "12px 16px",
    margin: "0px auto",
    textAlign: "center",
    transition: theme.transitions.create("padding", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    justifyContent: "center",
    ".MuiListItemIcon-root": {
      justifyContent: "center",
      color: theme.palette.grey[300],
    },
    "&.Mui-selected, &:hover": {
      ".MuiListItemIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    ".MuiListItemText-root": {
      display: "none",
    },
    "&:after": {
      background: "url(/pin-collapsed.png) no-repeat center right",
      top: "24px",
      width: "20px",
      height: "44px",
    },
  },
});

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .MuiListItemIcon-root {
    min-width: 32px;
  }
`;

interface ClientMenuProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  items: Array<NavItemData | ReactNode>;
  client: Client;
  disableInactiveLinks?: boolean;
}

export default function ClientMenu({
  client,
  items,
  open,
  setOpen,
  disableInactiveLinks,
}: ClientMenuProps) {
  // TODO: extract menuItems / List into elements to pre-style and use here (and in other menus)

  const userContext = useContext(UserContext);
  const { user, logout } = userContext;
  const router = useRouter();

  const menuItems = (
    <List disablePadding sx={{ pb: 2 }}>
      {items.map((navItem) => {
        if (React.isValidElement(navItem)) {
          return navItem;
        } else {
          return (
            <NavItem
              disableInactiveLinks={disableInactiveLinks}
              key={(navItem as NavItemData).label}
              item={navItem as NavItemData}
            />
          );
        }
      })}
    </List>
  );

  const handleDrawerClose = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledDrawer
        variant="permanent"
        open={open}
        sx={(theme) => {
          return {
            height: "100vh",
            color: "white",
            top: user?.is_impersonating ? "20px" : 0,
            ".MuiDrawer-paper": {
              display: "block",
              color: "white",
              backgroundColor: theme.palette.primary.darkest,
              "&::-webkit-scrollbar": {
                display: "none",
              },
              width: open ? drawerWidth : miniDrawerWidth,
              //  [
              //   `${open ? drawerWidth : miniDrawerWidth}px !important`,
              //   `${open ? drawerWidth : miniDrawerWidth}px !important`,
              //   `${open ? drawerWidth : miniDrawerWidth}px !important`,
              //   `${open ? drawerWidth : miniDrawerWidth}px !important`,
              // ],
            },
            width: open ? drawerWidth : miniDrawerWidth,

            flexShrink: 0,
            whiteSpace: "nowrap",
            boxSizing: "border-box",
            ".MuiIconButton-root": {
              color: "white",
            },
            ...(open && {
              ...openedMixin(theme),
              "& .MuiDrawer-paper": openedMixin(theme),
            }),
            ...(!open && {
              ...closedMixin(theme),
              "& .MuiDrawer-paper": closedMixin(theme),
            }),
          };
        }}
        anchor="left"
      >
        <GridContainer
          column
          justifyContent="space-between"
          sx={{
            flexWrap: "nowrap",
            minHeight: "100%",
            height: "auto",
          }}
        >
          <GridItem sx={{ flexGrow: 1 }}>
            <GridContainer column>
              <GridItem>
                <GridContainer justifyContent={open ? "flex-end" : "center"}>
                  <GridItem>
                    <IconButton onClick={handleDrawerClose}>
                      <CodeIcon />
                    </IconButton>
                  </GridItem>
                </GridContainer>
              </GridItem>
              <GridItem>
                <Box
                  sx={{
                    padding: open ? "16px 14px 50px" : "16px 8px 50px",
                    textAlign: open ? "left" : "center",
                  }}
                >
                  <Link href="/">
                    <Logo small={!open} />
                  </Link>
                  {user && user.client && (
                    <Overline sx={{ pl: 2 }}>
                      {open ? user.client?.name : ""}
                    </Overline>
                  )}
                </Box>
              </GridItem>
              <GridItem>{menuItems}</GridItem>
            </GridContainer>
          </GridItem>
          <GridItem sx={{ flexShrink: 1 }}>
            <GridContainer
              column
              justifyContent="flex-end"
              sx={{ height: "100%", flexWrap: "nowrap" }}
            >
              <GridItem>
                <List disablePadding sx={{ pb: 2 }}>
                  {user?.user_type === UserType.client && (
                    <NavItem
                      disableInactiveLinks={disableInactiveLinks}
                      key="configuration"
                      item={{
                        icon: <ConfigurationIcon />,
                        label: "Configuration",
                        url:
                          client && clientPath({ client, configuration: true }),
                      }}
                    />
                  )}
                  <StyledListItem
                    onClick={async () => {
                      setOpen(false);
                      await logout();
                      if (user && user.user_type === UserType.insured) {
                        router.push(
                          routes.loginPath({ client_id: user.client_id })
                        );
                      } else {
                        router.push("/");
                      }
                    }}
                    key="logout"
                    sx={{ cursor: "pointer" }}
                  >
                    <ListItemIcon>
                      <LogoutIcon />
                    </ListItemIcon>
                    <ListItemText>Logout</ListItemText>
                  </StyledListItem>
                </List>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </StyledDrawer>
      {/* <StyledBox>{menuItems}</StyledBox> */}
    </>
  );
}
