import { Typography } from "@mui/material";
import { formatDollars } from "dsl/money";
import Box from "./Box";

export interface DollarDisplayProps {
  value: number;
  large?: boolean;
  color?: string;
}

export default function DollarDisplay({
  value,
  large,
  color,
}: DollarDisplayProps) {
  const formattedString = formatDollars(value);
  const parts = formattedString.split(".");
  const fontSize = large ? "16px" : "14px";
  const variant = large ? "h2" : "h3";
  return (
    <Typography color={color} variant={variant} sx={{ whiteSpace: "nowrap" }}>
      {parts[0]}.
      <Box
        sx={{
          display: "inline",
          fontSize,
        }}
      >
        {parts[1]}
      </Box>
    </Typography>
  );
}
