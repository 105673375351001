import { CrudPathProps } from "routes/crud";
import { RetailAgency, Client, Maybe } from "types";

export interface AdminClientRetailAgencyPathProps extends CrudPathProps {
  client?: Maybe<Client>;
  retailAgency?: Maybe<RetailAgency>;
}

export const adminClientRetailAgencyPath = (
  opts?: AdminClientRetailAgencyPathProps
) => {
  if (opts?.client?.id && opts?.retailAgency?.id) {
    if (opts?.update) {
      return `/admin/clients/${opts.client.id}/retail-agencies/${opts.retailAgency.id}/edit/`;
    }
    // TODO: create part
  }
  return "";
};
