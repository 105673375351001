import {
  TableContainer,
  IconButton,
  TablePaginationProps,
} from "@mui/material";
import { MouseEvent, ChangeEvent, useCallback, useMemo } from "react";
import { Skeleton } from "@mui/material";
import Table, { Order, TableProps } from "./Table";
import { Filter } from "types/filter";
import { Maybe } from "types";
import GridContainer from "./GridContainer";
import ArrowBackIcon from "icon/arrow-back-icon";
import GridItem from "./GridItem";
import ArrowForwardIcon from "icon/arrow-forward-icon";
import { Subtitle } from "./typography";
import FilterTab from "./FilterTab";

export interface PaginationTableFilter {
  page: number;
  rowsPerPage: number;
  order?: Order;
  orderBy?: string;
}
export interface PaginationTableProps extends TableProps {
  paginationTableFilter: PaginationTableFilter;
  onPaginationTableFilterChange: (newFilter: PaginationTableFilter) => void;
  count: Maybe<number>;
  loaded: boolean;
  asCard?: boolean;
}

export function paginationTableFilterToAPIFilter(
  paginationFilter: PaginationTableFilter
): Filter {
  return {
    offset: paginationFilter.rowsPerPage * paginationFilter.page,
    limit: paginationFilter.rowsPerPage,
    order_by: paginationFilter.orderBy
      ? `${paginationFilter.orderBy} ${paginationFilter.order}`
      : undefined,
    include_total: true,
  };
}

function CustomTablePagination({
  onRowsPerPageChange,
  onPageChange,
  rowsPerPage,
  rowsPerPageOptions,
  page,
  count,
}: TablePaginationProps) {
  return (
    <GridContainer
      flexWrap="nowrap"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        mt: 0,
        pl: 1,
      }}
    >
      <GridItem>
        <GridContainer spacing={2} alignItems="center">
          <GridItem>
            <Subtitle>Rows per page</Subtitle>
          </GridItem>
          {(rowsPerPageOptions || []).map((r) => {
            const num = r as number;
            return (
              <GridItem key={num}>
                <FilterTab
                  min
                  active={rowsPerPage === num}
                  onClick={(event: any) => {
                    event.target.value = num;
                    if (onRowsPerPageChange) {
                      onRowsPerPageChange(event);
                    }
                  }}
                >
                  {num}
                </FilterTab>
              </GridItem>
            );
          })}
        </GridContainer>
      </GridItem>
      <GridItem>
        <GridContainer spacing={1} flexWrap="nowrap" alignItems="center">
          <GridItem>
            <GridContainer spacing={0} flexWrap="nowrap" alignItems="center">
              <GridItem>
                <Subtitle bold>Page {page + 1}</Subtitle>
              </GridItem>
              <GridItem>
                <Subtitle>
                  &nbsp;of {Math.max(1, Math.ceil(count / rowsPerPage))}
                </Subtitle>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem>
            <IconButton
              disabled={page === 0}
              onClick={(_event) => {
                onPageChange(_event, page - 1);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          </GridItem>
          <GridItem>
            <IconButton
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              onClick={(_event) => {
                onPageChange(_event, page + 1);
              }}
            >
              <ArrowForwardIcon />
            </IconButton>
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer>
  );
}

export default function PaginationTable({
  paginationTableFilter,
  onPaginationTableFilterChange,
  count,
  header,
  asCard,
  rows,
  loaded,
  ...tableProps
}: PaginationTableProps) {
  const { page, rowsPerPage, order, orderBy } = paginationTableFilter;

  const handleOnPageChange = useCallback(
    (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      onPaginationTableFilterChange({
        ...paginationTableFilter,
        page: newPage,
      });
    },
    [onPaginationTableFilterChange, paginationTableFilter]
  );

  const handleOnRowsPerPageChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      onPaginationTableFilterChange({
        ...paginationTableFilter,
        page: 0,
        rowsPerPage: parseInt(event.target.value, 10),
      });
    },
    [onPaginationTableFilterChange, paginationTableFilter]
  );

  const onSort = useCallback(
    (event: MouseEvent<unknown>, sortKey: string) => {
      const isAsc = orderBy === sortKey && order === "asc";
      onPaginationTableFilterChange({
        ...paginationTableFilter,
        order: isAsc ? "desc" : "asc",
        orderBy: sortKey,
      });
    },
    [orderBy, order, onPaginationTableFilterChange, paginationTableFilter]
  );

  // Modify rows for 2 reasons:
  // #1 - avoid layout jumps on the last page by inserting empty rows
  // #2 - add loading state to avoid layout jumps
  const newRows = useMemo(() => {
    const headerRow = header?.length && header[0];
    const headerRowColumns = headerRow ? headerRow : [];
    const numberOfHeaderRowColumns = headerRowColumns.length;

    if (!loaded) {
      const newRows = [];
      for (let i = 0; i < rowsPerPage; i++) {
        const newRow = [];
        for (let j = 0; j < numberOfHeaderRowColumns; j++) {
          newRow.push({ key: j, value: <Skeleton variant="text" /> });
        }
        newRows.push(newRow);
      }

      return newRows;
    }

    // Avoid a layout jump when reaching the last page with empty rows.
    const numberOfEmptyRows = page > 0 ? rowsPerPage - rows.length : 0;

    // If we have empty rows to show (to avoid layout jump), add them
    if (numberOfEmptyRows > 0) {
      // Fill in with zero width spaces
      const emptyRow = {
        selectable: false,
        cells: Array(numberOfHeaderRowColumns).fill("\u200B"),
      };
      const emptyRows = Array(numberOfEmptyRows).fill(emptyRow);
      return [...rows, ...emptyRows];
    }

    // Otherwise just return the rows unmodified
    return rows;
  }, [rows, loaded, page, rowsPerPage, header]);

  return (
    <TableContainer>
      <Table
        asCard={asCard}
        onSort={onSort}
        order={order}
        orderBy={orderBy}
        header={header}
        rows={newRows}
        {...tableProps}
      />
      <CustomTablePagination
        onRowsPerPageChange={handleOnRowsPerPageChange}
        onPageChange={handleOnPageChange}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        page={page}
        count={count == null ? -1 : count} //only set to -1 if count is undefined/null
      />
    </TableContainer>
  );
}
