import { Typography } from "@mui/material";
import { Theme } from "@mui/material";
import { GridContainer, GridItem, Overline } from "element";
import { ReactNode } from "react";
import DollarDisplay from "./DollarDisplay";
import { GridContainerProps } from "./GridContainer";

export interface CalloutLabelProps {
  color?: string;
  children?: ReactNode;
}

export interface CalloutValueProps {
  children: ReactNode;
  color?: string;
  large?: boolean;
  bold?: boolean;
}

export interface CalloutGroupProps extends GridContainerProps {
  children: ReactNode;
}

export interface CalloutProps {
  IconComponent?: any;
  color?: string;
  labelColor?: string;
  large?: boolean;
  bold?: boolean;
  label: string | ReactNode;
  children?: ReactNode;
  dollarValue?: number;
}

export function CalloutLabel({ color, children }: CalloutLabelProps) {
  return (
    <Overline
      color={color}
      sx={(theme: Theme) => {
        return {
          textTransform: "uppercase",
        };
      }}
    >
      {children}
    </Overline>
  );
}

export function CalloutValue({
  color,
  large,
  bold,
  children,
}: CalloutValueProps) {
  return (
    <Typography
      variant={large ? "h4" : "body1"}
      component="span"
      color={color}
      sx={{
        fontWeight: bold ? "600" : "400",
      }}
    >
      {children}
    </Typography>
  );
}

export function Callout({
  label,
  children,
  dollarValue,
  color,
  labelColor,
  large,
  bold,
  IconComponent,
}: CalloutProps) {
  const labelValue = (
    <GridContainer column spacing={1}>
      <GridItem>
        <CalloutLabel color={labelColor}>{label}</CalloutLabel>
      </GridItem>
      <GridItem>
        <CalloutValue color={color} large={large} bold={bold}>
          {dollarValue != null ? (
            <DollarDisplay large={large} color={color} value={dollarValue} />
          ) : (
            children
          )}
        </CalloutValue>
      </GridItem>
    </GridContainer>
  );
  if (IconComponent) {
    return (
      <GridItem xs="auto">
        <GridContainer spacing={2} alignItems="center">
          <GridItem>
            <IconComponent
              sx={(theme: Theme) => {
                return {
                  color: theme.palette.grey[100],
                  fontSize: "20px",
                };
              }}
            />
          </GridItem>
          <GridItem>{labelValue}</GridItem>
        </GridContainer>
      </GridItem>
    );
  } else {
    return <GridItem xs="auto">{labelValue}</GridItem>;
  }
}

export default function CalloutGroup({
  children,
  ...props
}: CalloutGroupProps) {
  return (
    <GridContainer spacing={4} {...props}>
      {children}
    </GridContainer>
  );
}
