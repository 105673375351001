import { CardActions, CardContent, CardHeader, CardMedia } from "@mui/material";
import { default as MuiCard } from "@mui/material/Card";
import { styled } from "@mui/material/styles";
import { ReactNode } from "react";
import { BORDER_RADIUS } from "theme";
import { colors } from "theme/palette";

export interface CardProps {
  title?: React.ReactNode;
  footer?: React.ReactNode;
  center?: boolean;
  selectable?: boolean;
  selected?: boolean;
  fullHeight?: boolean;
  topBar?: boolean;
  children?: React.ReactNode;
  mediaLeft?: React.ReactNode;
  mediaRight?: React.ReactNode;
  mediaTop?: React.ReactNode;
  mediaBottom?: React.ReactNode;
  disablePadding?: boolean;
  [x: string]: any;
}

// TODO extract selectable/other card types to other components

const StyledCard = styled(MuiCard)(
  ({ theme }) => `
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.06);

  margin-top: 8px;
  margin-bottom: 8px;
  height: calc(100% - 16px);
  position: relative;
  max-width: 100%;
  .MuiCardHeader-root {
    padding: 25px ;
  }
  &.MuiCardContent-root {
    padding: 25px;
    &:last-child {
      padding: 25px;
    }
  }
  &.content-card-no-padding {
    .MuiCardContent-root {
      padding: 0;
      &:last-child {
        padding: 0;
      }
    }
  }


  &.content-card-media {
    background-color: ${colors.primary[100]};
  }

  &.centered-card {
    text-align: center;
  }
  &.fullHeight-card {
    height: calc(100% - 16px);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &.selectable-card {
    cursor: pointer;
    box-shadow: none;
    &:hover {
      background-color: ${colors.secondary[300]}
    }
    &.selected {
      background-color: ${theme.palette.secondary.light}
    }
  }
  &.top-bar {
    border-radius: 0 0 ${BORDER_RADIUS}px ${BORDER_RADIUS}px;
    border-top: 7px solid ${theme.palette.primary.main};  
  }
`
);

export default function ContentCard({
  title,
  footer,
  center,
  fullHeight,
  topBar,
  selectable,
  selected,
  children,
  mediaLeft,
  mediaRight,
  mediaTop,
  mediaBottom,
  disablePadding,
  ...props
}: CardProps) {
  let className = props.className || "";
  if (disablePadding) {
    className += " content-card-no-padding";
  }
  if (center) {
    className += " centered-card";
  }
  if (fullHeight) {
    className += " fullHeight-card";
  }
  if (selectable) {
    className += " selectable-card";
  }
  if (selected) {
    className += " selected";
  }
  if (topBar) {
    className += " top-bar";
  }

  let mediaContent: ReactNode;
  if (mediaLeft) {
    mediaContent = (
      <CardMedia className={"content-card-media"}>{mediaLeft}</CardMedia>
    );
  }
  if (mediaTop) {
    mediaContent = (
      <CardMedia className={"content-card-media"}>{mediaTop}</CardMedia>
    );
  }
  if (mediaRight) {
    mediaContent = (
      <CardMedia className={"content-card-media"}>{mediaRight}</CardMedia>
    );
  }
  if (mediaBottom) {
    mediaContent = (
      <CardMedia className={"content-card-media"}>{mediaBottom}</CardMedia>
    );
  }

  return (
    <StyledCard
      sx={{ display: mediaLeft || mediaRight ? "flex" : "block" }}
      {...props}
      className={className}
    >
      {(mediaLeft || mediaTop) && mediaContent}
      {title && (
        <CardHeader
          sx={{
            padding: "25px 35px 0",
          }}
          titleTypographyProps={{
            variant: "h2",
          }}
          title={title}
        />
      )}
      <CardContent sx={{ flexGrow: 1 }}>{children}</CardContent>
      {(mediaRight || mediaBottom) && mediaContent}
      {footer && (
        <CardActions
          sx={{
            padding: "0 35px 25px",
            justifyContent: "flex-end",
          }}
        >
          {footer}
        </CardActions>
      )}
    </StyledCard>
  );
}
