import { useEffect, useState } from "react";

export default function usePersistantState<T>(
  varName: string,
  initialValue: T
): [T, (arg: T) => void] {
  const [varValue, setVarValue] = useState<T>(initialValue);

  useEffect(() => {
    const storedValue = window?.localStorage?.getItem(varName);
    if (storedValue !== null) {
      const newValue = JSON.parse(storedValue);
      setVarValue(newValue);
    }
  }, [varName]);

  const storeValue = (val: T) => {
    window.localStorage.setItem(varName, JSON.stringify(val));
    setVarValue(val);
  };

  return [varValue, storeValue];
}
