import { addQuery, CrudPathProps } from "routes/crud";
import { Checkout, Client } from "types";

export interface ClientCheckoutPathProps extends CrudPathProps {
  checkout?: Checkout;
  preview?: boolean;
}

export const clientCheckoutPath = (
  client: Client,
  opts?: ClientCheckoutPathProps
) => {
  if (!client) {
    return "";
  }
  let url = "";
  if (opts?.checkout) {
    if (opts.update) {
      url = `/clients/${client.id}/checkout-links/${opts.checkout.id}/edit`;
    } else if (opts.preview) {
      url = `/clients/${client.id}/checkout-links/${opts.checkout.id}/preview`;
    } else {
      url = `/clients/${client.id}/checkout-links/${opts.checkout.id}/`;
    }
  } else {
    if (opts?.create) {
      url = `/clients/${client.id}/checkout-links/new`;
    } else {
      url = `/clients/${client.id}/checkout-links/`;
    }
  }
  if (opts?.query) {
    url = addQuery(url, opts.query);
  }
  return url;
};
