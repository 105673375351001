import { BankAccount, Client, Maybe } from "types";
import { clientMoneyInPath } from "./client-money-in";

interface ClientBankAccountPathProps {
  bank_account?: Maybe<BankAccount>;
}

export const clientBankAccountPath = (
  client: Client,
  opts?: ClientBankAccountPathProps
) => {
  if (opts?.bank_account?.id) {
    return `${clientMoneyInPath(client)}bank-accounts/${opts.bank_account.id}/`;
  } else {
    return `${clientMoneyInPath(client)}banking/`;
  }
};
