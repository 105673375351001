import { Tooltip } from "element";
import { ReactChild, ReactFragment, ReactPortal } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Theme } from "@mui/material";

interface TooltipIconParms {
  children: boolean | ReactChild | ReactFragment | ReactPortal;
  fontSize?: string;
}

export default function TooltipIcon({ children, fontSize }: TooltipIconParms) {
  return (
    <Tooltip title={children}>
      <InfoOutlinedIcon
        sx={{
          color: (theme: Theme) => theme.palette.grey[100],
          fontSize: fontSize || "20px",
          "&:hover": {
            color: "primary.main",
          },
        }}
      />
    </Tooltip>
  );
}
