import { Box } from "element";
import { SxProps, Theme } from "@mui/material";
import { ReactNode, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { BUTTON_RADIUS } from "theme";

interface SelectableBoxProps {
  onSelect: () => void;
  selected?: boolean;
  noHover?: boolean;
  transparent?: boolean;
  children: ReactNode;
  sx?: SxProps<Theme>;
  disabled?: boolean;
}

const StyledBox = styled(Box)(
  ({ theme }) => `
  cursor: pointer;
  height: 100%;
  background: ${theme.palette.background.paper};
  border-radius: ${BUTTON_RADIUS}px;
  text-align: center;
  padding: ${theme.spacing(2)};
  display: flex;
  align-items: center;
  color: ${theme.palette.text.secondary};
  border: 3px solid ${theme.palette.background.default};
  &.selectable-box-selected {
    color: ${theme.palette.primary.main};
    border: 3px solid ${theme.palette.primary.main};
  }
  &.transparent {
    background: none;
    border: 1px solid ${theme.palette.primary.main};
  }
  &:hover {
    background: ${theme.palette.primaryLight.light};
  }
  &.no-hover:hover {
    background: ${theme.palette.background.paper};
  }
  `
);

export default function SelectableBox({
  onSelect,
  selected,
  noHover,
  transparent,
  children,
  sx,
  disabled,
}: SelectableBoxProps) {
  const actualSx = useMemo(() => {
    if (disabled) {
      return { ...sx, color: "gray" };
    }
    return sx;
  }, [sx, disabled]);

  let className = "";
  if (selected) {
    className += " selectable-box-selected";
  }
  if (noHover) {
    className += " no-hover";
  }
  if (transparent) {
    className += " transparent";
  }

  return (
    <StyledBox
      sx={actualSx}
      className={className}
      onClick={() => !disabled && onSelect()}
    >
      {children}
    </StyledBox>
  );
}
