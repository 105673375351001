import { CrudPathProps } from "routes/crud";
import { Client, Maybe, PremiumFinancePartner, Product } from "types";

export interface AdminClientCrudPathProps extends CrudPathProps {
  client?: Maybe<Client>;
}

export const adminClientPath = (opts?: AdminClientCrudPathProps) => {
  if (opts?.client) {
    if (opts?.update) {
      return `/admin/clients/${opts.client.id}/edit/`;
    } else {
      return `/admin/clients/${opts.client.id}/`;
    }
  } else {
    if (opts?.create) {
      return "/admin/clients/new/";
    } else {
      return "/admin/clients/";
    }
  }
};

export const adminClientInvoicesPath = (client: Client) => {
  return `/admin/clients/${client.id}/invoices/`;
};

export const adminClientSetupPath = (client: Client) => {
  return `/admin/clients/${client.id}/setup/`;
};

export const adminClientPremiumFinanceSetupPath = (client: Client) => {
  return `/admin/clients/${client.id}/premium-finance-setup/`;
};

export const adminCustomPolicyItemTypesPath = (client: Client) => {
  return `/admin/clients/${client.id}/client-policy-item-types/`;
};

interface AdminClientProductCrudPathProps extends CrudPathProps {
  product?: Product;
}

export const adminClientProductsPath = (
  client: Client,
  opts?: AdminClientProductCrudPathProps
) => {
  if (opts?.product?.id) {
    if (opts?.update) {
      return `/admin/clients/${client.id}/products/${opts.product.id}/edit/`;
    } else {
      return `/admin/clients/${client.id}/products/${opts.product.id}/`;
    }
  } else {
    if (opts?.create) {
      return `/admin/clients/${client.id}/products/new/`;
    } else {
      return `/admin/clients/${client.id}/products/`;
    }
  }
};

export const adminClientUsersPath = (client: Client) => {
  return `/admin/clients/${client.id}/users/`;
};

export const adminClientAgencyPartnersPath = (client: Client) => {
  return `/admin/clients/${client.id}/agencies/`;
};

export const adminClientEndInsuredsPath = (client: Client) => {
  return `/admin/clients/${client.id}/insureds/`;
};

export const adminClientRetailAgenciesPath = (client: Client) => {
  return `/admin/clients/${client.id}/retail-agencies/`;
};

export const adminClientPremiumFinancePartnersPath = (client: Client) => {
  return `/admin/clients/${client.id}/premium-finance-partners/`;
};

export const adminClientPremiumFinancePartnerEditPath = (
  client: Maybe<Client>,
  pfPartner: PremiumFinancePartner
) => {
  return client
    ? `/admin/clients/${client.id}/premium-finance-partners/${pfPartner.id}/edit`
    : "";
};
