import { CrudPathProps, addQuery } from "routes/crud";
import { Client } from "types";
import { Submission } from "types/submission";

export type ClientSubmissionTab =
  | "policies"
  | "premium-finance"
  | "billing"
  | "payables"
  | "payments"
  | "comms"
  | "carts";

export const ClientSubmissionTabOptions: ClientSubmissionTab[] = [
  "policies",
  "premium-finance",
  "billing",
  "payables",
  "payments",
  "comms",
  "carts",
];

export interface ClientSubmissionsPathProps extends CrudPathProps {
  submission?: Submission;
  submissionId?: string;
  tab?: ClientSubmissionTab;
}

export const clientSubmissionsPath = (
  client: Client,
  opts?: ClientSubmissionsPathProps
) => {
  if (!client) {
    return "";
  }
  const submissionId = opts?.submission?.id || opts?.submissionId;
  let url = "";
  if (submissionId) {
    if (opts.update) {
      url = `/clients/${client.id}/submissions/${submissionId}/edit`;
    } else {
      if (opts?.tab) {
        url = `/clients/${client.id}/submissions/${submissionId}/${opts.tab}/`;
      } else {
        url = `/clients/${client.id}/submissions/${submissionId}/policies`;
      }
    }
  } else {
    if (opts?.create) {
      url = `/clients/${client.id}/submissions/new`;
    } else {
      url = `/clients/${client.id}/submissions/`;
    }
  }
  if (opts?.query) {
    url = addQuery(url, opts.query);
  }
  return url;
};
