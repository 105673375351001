import { InputAdornment } from "@mui/material";
import TextField from "@mui/material/TextField";
import WithFieldErrors from "element/form/WithFieldErrors";
import { ChangeEventHandler, KeyboardEventHandler, ReactNode } from "react";
import { FormFieldProps } from "types";

// import withFieldErrors from 'elements/form/withFieldErrors';
//import withTranslatedLabel from 'elements/form/withTranslatedLabel';

export interface InputProps extends FormFieldProps {
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  onEnter?: () => void;
  children?: ReactNode;
  submitOnEnter?: boolean;
  small?: boolean;
  number?: boolean;
}

const Input = ({
  formContext,
  label,
  onChange,
  onEnter,
  hasError,
  instructions,
  startIcon,
  endIcon,
  children,
  submitOnEnter,
  small,
  number,
  type,
  ...props
}: InputProps) => {
  let handleChange;
  if (onChange) {
    handleChange = onChange;
  } else if (formContext && props.name) {
    handleChange = (event: any) => {
      formContext.updateField(props.name as string, event.target.value);
    };
  }

  let controlledValue =
    props.value != null
      ? props.value
      : formContext?.data && props.name && formContext.data[props.name];
  if (controlledValue == null) {
    controlledValue = "";
  }

  const detectKey: KeyboardEventHandler<
    HTMLTextAreaElement | HTMLInputElement
  > = (e) => {
    if (e.key === "Enter") {
      if (submitOnEnter) {
        e.preventDefault();
        e.stopPropagation();
        formContext?.submitForm && formContext?.submitForm();
      } else if (onEnter) {
        e.preventDefault();
        e.stopPropagation();
        onEnter();
      }
    }
  };

  const inputProps = {
    onKeyDown: detectKey,
    ...(props.InputProps || {}),
  };

  if (startIcon) {
    inputProps.startAdornment = (
      <InputAdornment sx={{ color: "inherit" }} position="start">
        {startIcon}
      </InputAdornment>
    );
  }
  if (endIcon) {
    inputProps.endAdornment = (
      <InputAdornment sx={{ color: "inherit" }} position="end">
        {endIcon}
      </InputAdornment>
    );
  }

  if (props.multiline) {
    // set default minRows
    props.minRows ||= 4;
    props.maxRows ||= 4;
  }

  const size = small ? "small" : "medium";

  // Note explicit type takes precedence over number/text distinction
  const textFieldType = type ? type : number ? "number" : "text";

  return (
    <TextField
      variant="outlined"
      {...props}
      error={hasError}
      helperText={instructions}
      label={label}
      size={size}
      type={textFieldType}
      onChange={handleChange}
      value={controlledValue}
      InputProps={inputProps}
      InputLabelProps={{
        ...(props.InputLabelProps || {}),
      }}
    >
      {children}
    </TextField>
  );
};

export default WithFieldErrors(Input);
//export default withTranslatedLabel(withFieldErrors(Input));
