import { PaletteOptions } from "@mui/material";
import { lightBlue } from "@mui/material/colors";

export const purple = {
  100: "#ECE6F9",
  lighter: "#ECE6F9",
  300: "#976BF3", //light
  light: "#976BF3", //light
  400: "#C4C5C8",
  500: "#7F56D9", //CTA
  main: "#7F56D9", //CTA
  600: "#6A48BD",
  700: "#633DBD", //dark
  dark: "#633DBD", //dark
  800: "#5C33B7",
  darker: "#5C33B7",
  900: "#0C0420", //dark purple
  darkest: "#0C0420", //dark purple
};

export const grey = {
  25: "#F9FAFF",
  50: "#E2E2E2",
  100: "#C4C5C8",
  200: "#A7A7AD",
  300: "#93949B",
  400: "#808089",
  500: "#767680",
  main: "#767680",
  600: "#3B3C49",
  900: "#222223",
};

export const blue = {
  300: "#D9F2FD",
  light: "#D9F2FD",
  500: "#03A9F4",
  main: "#03A9F4",
  700: "#006C9D",
  dark: "#006C9D",
};

export const green = {
  300: "#E3F9EA",
  light: "#E3F9EA",
  500: "#16A34A",
  main: "#16A34A",
  700: "#037D30",
  dark: "#037D30",
};

export const orange = {
  300: "#F4E8D9",
  light: "#F4E8D9",
  500: "#F95A00",
  main: "#F95A00",
  700: "#B25600",
  dark: "#B25600",
};

export const white = "#FFFFFF";

declare module "@mui/material/styles" {
  interface Palette {
    appBarBackground: Palette["primary"];
    primaryLight: Palette["primary"];
    secondaryLight: Palette["primary"];
    backgroundGradient?: string;
    backgroundHeader?: string;
    backgroundSubheader?: string;
  }
  interface PaletteOptions {
    appBarBackground: PaletteOptions["primary"];
    primaryLight: PaletteOptions["primary"];
    secondaryLight: PaletteOptions["primary"];
    backgroundGradient?: string;
    backgroundHeader?: string;
    backgroundSubheader?: string;
  }
  interface PaletteColor {
    25?: string;
    lighter?: string;
    darker?: string;
    darkest?: string;
  }
}

export const gradients = {
  //dark: "linear-gradient(101deg, rgba(35, 11, 49, 0.8) 17.56%, rgba(23, 12, 61, 0.8) 52.39%, rgba(23, 11, 49, 0.8) 83.77%), #FFFFFF",
  //default: "linear-gradient(-15deg, #886BD0 10%, #C47DD0 35%, #FFFFFF 100%)",
  //light: "linear-gradient(320deg, #34BFFF 12.84%, #C6EDFF 85.72%)",

  default: "linear-gradient(107.3deg, #53389E 0%, #7F56D9 98.79%)",
};

export const colors = {
  primary: purple,
  secondary: blue,
  info: grey,
  warning: orange,
  success: green,
  error: orange, //?
};

export const chartColors = [
  purple[500],
  lightBlue[500],
  green[500],
  //orange[500],

  purple[300],
  lightBlue[200],
  green[500],
  //orange[200],

  purple[100],
  lightBlue[50],
  green[300],
  //orange[50],
];

const palette: PaletteOptions = {
  primary: purple,
  primaryLight: {
    main: purple[500],
    light: purple[300],
    dark: purple[700],
  },
  secondary: blue,
  secondaryLight: {
    main: blue[300],
  },
  success: green,
  info: blue,
  warning: orange,
  error: orange,
  appBarBackground: {
    main: "#FFFFFF",
  },
  background: {
    default: grey[50],
  },
  backgroundHeader: grey[200],
  backgroundSubheader: grey[100],
  text: {
    primary: grey[600],
    secondary: grey[400],
  },
  backgroundGradient: gradients.default,
  grey,
};

export default palette;
