import { CrudPathProps } from "routes/crud";
import { Client } from "types";

export type ClientPremiumFinancePathTab =
  | "contracts"
  | "quotes"
  | "partners"
  | "retail-agencies";

export const ClientPremiumFinancePathTabOptions: ClientPremiumFinancePathTab[] =
  ["contracts", "quotes", "partners", "retail-agencies"];

export interface ClientPremiumFinancePathProps extends CrudPathProps {
  tab?: ClientPremiumFinancePathTab;
}

export const clientPremiumFinancePath = (
  client: Client,
  opts?: ClientPremiumFinancePathProps
) => {
  const prefix = `/clients/${client.id}/premium-finance/`;

  if (opts?.tab) {
    return `${prefix}${opts?.tab}`;
  }
  return prefix;
};
