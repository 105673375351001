import { Button, GridContainer, GridItem, Input } from "element";
import ArrowForwardIcon from "icon/arrow-forward-icon";
import CloseIcon from "icon/close-icon";
import { useCallback, useState } from "react";
import { Maybe } from "types";

export interface SearchInputProps {
  search?: Maybe<string>;
  onExecuteSearch?: (search: string) => void;
}

export default function SearchInput({
  search,
  onExecuteSearch,
}: SearchInputProps) {
  const [searchText, setSearchText] = useState<string>(search || "");
  const onChangeSearch = useCallback(
    (newValue?: string) => {
      if (newValue != null) {
        setSearchText(newValue);
      }
      onExecuteSearch && onExecuteSearch(newValue ?? searchText);
    },
    [searchText, onExecuteSearch]
  );

  return (
    <GridContainer alignItems="center">
      <GridItem>
        <Input
          small
          label="Search"
          value={searchText}
          onEnter={onChangeSearch}
          onChange={(event: any) => {
            setSearchText(event.target?.value.toString());
          }}
          endIcon={
            searchText ? (
              <CloseIcon
                color="disabled"
                onClick={() => {
                  onChangeSearch("");
                }}
              />
            ) : null
          }
        />
      </GridItem>
      <GridItem>
        <Button
          disabled={searchText === search}
          onClick={() => onChangeSearch()}
        >
          <ArrowForwardIcon />
        </Button>
      </GridItem>
    </GridContainer>
  );
}
