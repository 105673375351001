import { CrudPathProps, addQuery } from "routes/crud";
import { Client, Policy } from "types";

export type ClientPolicyPathTab =
  | "policy-events"
  | "payables"
  | "receivables"
  | "premium-finance"
  | "comms";

export const ClientPolicyPathTabOptions: ClientPolicyPathTab[] = [
  "policy-events",
  "payables",
  "receivables",
  "premium-finance",
  "comms",
];

export interface ClientPoliciesPathProps extends CrudPathProps {
  policy?: Policy;
  policy_id?: string;
  tab?: ClientPolicyPathTab;
}

export const clientPoliciesPath = (
  client: Client,
  opts?: ClientPoliciesPathProps
) => {
  if (!client) {
    return "";
  }
  let url = "";
  if (opts?.policy || opts?.policy_id) {
    const policy_id = opts?.policy?.id || opts?.policy_id;
    if (opts.update) {
      url = `/clients/${client.id}/policies/${policy_id}/edit`;
    } else {
      if (opts.tab) {
        url = `/clients/${client.id}/policies/${policy_id}/${opts.tab}/`;
      } else {
        url = `/clients/${client.id}/policies/${policy_id}/policy-events`;
      }
    }
  } else {
    if (opts?.create) {
      url = `/clients/${client.id}/policies/new`;
    } else {
      url = `/clients/${client.id}/policies/`;
    }
  }
  if (opts?.query) {
    url = addQuery(url, opts.query);
  }
  return url;
};
