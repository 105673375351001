import { SxProps } from "@mui/material";
import { GridContainer, GridItem } from "element";
import { ReactElement } from "react";

import { styled } from "@mui/material/styles";

const StyledGridItem = styled(GridItem)(
  ({ theme }) => `
  
`
);

export interface KeyValueListItem {
  key: string | number | boolean | null | undefined | ReactElement;
  value: string | number | boolean | null | undefined | ReactElement;
  sx?: SxProps;
  keySx?: SxProps;
  valueSx?: SxProps;
}

export interface KeyValueListProps {
  header?: string;
  sx?: SxProps;
  headerSx?: SxProps;
  items: Array<KeyValueListItem>;
  spacing?: number;
}

export default function KeyValueList({
  header,
  items,
  sx,
  headerSx,
  spacing = 4,
}: KeyValueListProps) {
  return (
    <GridContainer
      column
      sx={{
        typography: "body1",
        ...(sx || {}),
      }}
      spacing={spacing}
    >
      {header && (
        <StyledGridItem
          key="header"
          sx={{
            typography: "overline",
            ...(headerSx || {}),
          }}
        >
          {header}
        </StyledGridItem>
      )}
      {items.map((item, idx) => {
        return (
          <StyledGridItem
            key={item.key?.toString() || idx}
            className={
              idx % 2 === 1 ? "key-value-row-even" : "key-value-row-odd"
            }
          >
            <GridContainer
              spacing={2}
              alignItems="center"
              justifyContent="space-between"
              sx={{
                ...item.sx,
              }}
              flexWrap="nowrap"
            >
              <GridItem sx={item.keySx}>{item.key}</GridItem>
              <GridItem
                sx={{
                  textAlign: "right",
                  color: "text.secondary",
                  ...(item.valueSx || {}),
                }}
              >
                {item.value}
              </GridItem>
            </GridContainer>
          </StyledGridItem>
        );
      })}
    </GridContainer>
  );
}
