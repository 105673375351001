import { FormHelperText, IconButton } from "@mui/material";
import { default as MUIButton } from "@mui/material/Button";

import ArrowForwardOutlinedIcon from "icon/arrow-forward-icon";
import ArrowBackOutlinedIcon from "icon/arrow-back-icon";

import { ReactNode } from "react";
import AddIcon from "icon/add-icon";
import EditIcon from "icon/edit-icon";
import DeleteIcon from "icon/delete-icon";
import DownloadIcon from "icon/download-icon";
import UploadIcon from "icon/upload-icon";

export interface ButtonProperties {
  main?: boolean;
  forward?: boolean;
  back?: boolean;
  tablecta?: boolean;

  disabled?: boolean;

  withInstructions?: boolean;

  next?: boolean;
  prev?: boolean;
  add?: boolean;
  edit?: boolean;
  deleteButton?: boolean;
  deleteIcon?: boolean;
  download?: boolean;
  upload?: boolean;
  icon?: ReactNode;
  children?: React.ReactNode;

  [x: string]: any;
}

const Button = ({
  main,
  forward,
  back,
  tablecta,

  disabled,
  withInstructions = false,

  icon,
  next,
  prev,
  add,
  edit,
  deleteButton,
  deleteIcon,
  download,
  upload,
  children,

  ...props
}: ButtonProperties) => {
  const muiVariant = forward ? "contained" : "outlined";

  let className = "";

  let size: "small" | "medium" | "large" = "medium";
  if (main) {
    size = "large";
  } else if (back || tablecta) {
    size = "small";
    className = "back";
    if (tablecta) {
      className = "table-cta";
    }
  }

  if (edit) {
    return (
      <IconButton disabled={disabled} color="default" size={size} {...props}>
        <EditIcon />
      </IconButton>
    );
  }
  if (deleteIcon) {
    return (
      <IconButton disabled={disabled} color="default" size={size} {...props}>
        <DeleteIcon />
      </IconButton>
    );
  }

  const iconProps: {
    endIcon?: ReactNode;
    startIcon?: ReactNode;
  } = {};

  if (icon) {
    iconProps.endIcon = icon;
  } else if (next) {
    iconProps.endIcon = <ArrowForwardOutlinedIcon />;
  } else if (prev) {
    iconProps.startIcon = <ArrowBackOutlinedIcon />;
  } else if (add) {
    iconProps.endIcon = <AddIcon />;
  } else if (deleteButton) {
    iconProps.endIcon = <DeleteIcon />;
  } else if (download) {
    iconProps.startIcon = <DownloadIcon />;
  } else if (upload) {
    iconProps.startIcon = <UploadIcon />;
  }

  return (
    <>
      <MUIButton
        {...iconProps}
        className={className}
        variant={muiVariant}
        disabled={disabled}
        disableRipple
        size={size}
        {...props}
      >
        {children}
      </MUIButton>
      {withInstructions && <FormHelperText>&nbsp;</FormHelperText>}
    </>
  );
};

export default Button;
