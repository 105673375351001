import { Alert, AlertColor, Snackbar } from "@mui/material";
import { ReactNode } from "react";

interface ToastProps {
  open: boolean;
  children: ReactNode;
  severity?: AlertColor;
  onClose(): void;
  variant?: "standard" | "filled" | "outlined";
}

const DURATION = 6000;

export default function Toast({
  open,
  children,
  severity = "success",
  onClose,
  variant,
}: ToastProps) {
  return (
    <Snackbar open={open} onClose={onClose} autoHideDuration={DURATION}>
      <Alert variant={variant} severity={severity}>
        {children}
      </Alert>
    </Snackbar>
  );
}
