import { Typography } from "@mui/material";
import { TypographyProps } from "./typography-props";

export default function Body2({ children, bold, ...props }: TypographyProps) {
  return (
    <Typography
      variant="body2"
      sx={{
        fontWeight: bold ? 600 : 400,
        ...(props.sx || {}),
      }}
      {...props}
    >
      {children}
    </Typography>
  );
}
