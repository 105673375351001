import { CrudPathProps } from "routes/crud";
import { PremiumFinancePartner, Client, Maybe } from "types";
import { clientPremiumFinancePath } from "./client-premium-finance";

export type ClientPremiumFinancePartnerTab =
  | "policies"
  | "invoices"
  | "payments"
  | "credits"
  | null;

export interface ClientPremiumFinancePartnerPathProps extends CrudPathProps {
  premium_finance_partner?: Maybe<PremiumFinancePartner>;
  pf_client_id?: Maybe<string>;
  tab?: ClientPremiumFinancePartnerTab;
}

export const clientPremiumFinancePartnerPath = (
  client: Client,
  opts?: ClientPremiumFinancePartnerPathProps
) => {
  const pf_client_id = opts?.premium_finance_partner?.id || opts?.pf_client_id;
  const prefix = clientPremiumFinancePath(client);
  if (pf_client_id) {
    if (opts?.tab) {
      return `${prefix}partners/${pf_client_id}/${opts.tab}/`;
    } else {
      return `${prefix}partners/${pf_client_id}/policies`;
    }
  } else {
    return `${prefix}partners/`;
  }
};
