import { DataObject } from "./data-object";
import { PaymentMethod, Policy, PolicyEvent } from "types";
import { BilledToType } from "./billed-to";

export type Checkout = DataObject & {
  pf_quote_id?: string;
  consolidated_invoice_id?: string;
  client_id: string;

  billed_to_id?: string;
  billed_to_type?: BilledToType;

  invoice_count?: number;
  consolidated_invoice_num?: string;
};

export enum CheckoutDetailsStatus {
  complete = "complete",
  incomplete = "incomplete",
}

export enum CheckoutDetailsBillingType {
  pay_in_full = "pay_in_full",
  premium_finance = "premium_finance",
}

export type CheckoutDetails = DataObject & {
  status: CheckoutDetailsStatus;
  timestamp?: Date;
  checkout_id: string;
  pf_quote_id?: string;
  consolidated_invoice_id?: string;
  billing_type_selected?: CheckoutDetailsBillingType;
  amount_paid?: number;
  payment_method?: PaymentMethod;
  payment_id?: string;
  pf_contract_id?: string;
  policy_events?: Array<PolicyEvent>;
  policies?: Array<Policy>;
};
