import { Maybe } from "./";
import { DataObject } from "./data-object";
import { Client } from "./client";
import { PolicyEvent } from "./policy";
import { EndInsured } from "./end-insured";

export type PremiumFinanceSettings = DataObject & {
  pf_client_id: string;
  honor_capital_ff_general_agency_id?: string;
};

export type PremiumFinanceRateScheduleItem = DataObject & {
  pf_compenstaion_id: string;
  min_financed_amt: number;
  max_financed_amt?: number;
  base_interest_rate_pct: number;
  additional_take_rate_pct?: number;
};

export type PremiumFinanceRateSchedule = DataObject & {
  client_id: string;
  pf_client_id: string;
  carrier_id?: string;
  coverage_type_id?: string;
  rate_schedule_items?: Array<PremiumFinanceRateScheduleItem>;
};

export type PremiumFinanceDownpaymentSetting = DataObject & {
  client_id: string;
  pf_client_id: string;
  coverage_type_id?: string;
  min_downpayment_pct: number;
  include_taxes_in_downpayment: boolean;
  include_fees_in_downpayment: boolean;
  min_financed_amt?: number;
  max_financed_amt?: number;
  for_endorsements: boolean;
};

export type PremiumFinancePartner = DataObject & {
  client_id: string;
  pf_client_id: string;
  pf_client?: Client;
  honor_capital_general_agency_id?: string;
  rate_schedule?: PremiumFinanceRateSchedule;
  downpayment_settings?: Array<PremiumFinanceDownpaymentSetting>;
  balance_due_amt?: number;
  past_due_amt?: number;
};

export enum PremiumFinanceQuoteStatus {
  saved = "saved",
  approved = "approved",
  pending_approval = "pending_approval",
  bound = "bound",
  void = "void",
  rejected = "rejected",
  printed = "printed",
}

export type PremiumFinanceQuotePolicyEventAllocation = DataObject & {
  pf_quote_id: string;
  policy_event_id: string;
  funding_amt?: number;
  policy_event?: PolicyEvent;
};

export type PremiumFinanceQuote = DataObject & {
  client_id: string;
  client: Client;
  pf_client_id: string;
  insured_id: string;
  insured: EndInsured;
  policy_event_allocations: PremiumFinanceQuotePolicyEventAllocation[];
  status?: PremiumFinanceQuoteStatus;
  num_installments?: number;
  due_to_pfc_amt?: number;
  downpayment_amt?: number;
  downpayment_pct?: number;
  doc_stamps?: number;
  actual_interest_rate_pct?: number;
  term_months?: number;

  finance_charge?: number;
  financed_amt?: number;
  financing_method?: string;
  interest_amt?: number;
  max_interest_rate?: number;
  min_interest_rate?: number;
  min_downpayment_pct_allowed?: number;
  payment_amt?: number;
  interest_rate_pct?: number;
  producer_fee?: number;
  service_charge_amt?: number;
  total_of_payments?: number;
  unpaid_balance_amt?: number;
  additional_info?: string;
  is_approval_required?: boolean;
  late_days?: number;
  late_charge?: number;
  first_payment_due: string;

  accepted_at?: string;
  bound_at?: string;

  pf_contract_id?: string;
  errors?: string;
  agency_signatory_name?: string;
  retail_agency_id?: string;
  honor_capital_agency_id?: string;
  honor_capital_quote_id?: string;
  is_active?: boolean;
  is_endorsement?: boolean;
  is_expired?: boolean;

  pf_contract?: Maybe<PremiumFinanceContract>;
};

export enum PremiumFinanceDocumentType {
  bundle = "bundle",
  drafts = "drafts",
  receipts = "receipts",
  ach_application = "achapplication",
  finance_coupons = "financecoupons",
  finance_agreement = "financeagreement",
  payment_instructions = "paymentinstructions",
}

export type PremiumFinancePayment = DataObject & {
  payment_date: string;
  amount: number;
  pf_contract_id: string;
  description?: string;
  status_description?: string;
};

export type PremiumFinanceContract = DataObject & {
  client_id: string;
  pf_client_id: string;
  insured_id: string;
  originating_pf_quote_id: string;
  num_payments_left?: number;
  num_payments_total?: number;
  current_due_amt?: number;
  last_payment_amt?: number;
  last_payment_date?: string;
  due_date?: string;
  account_balance_amt?: number;
  monthly_payment_amt?: number;
  payment_status_description?: string;
  is_payment_allowed?: boolean;
  is_recurring_payment_scheduled?: boolean;
  cancellation_held_until_date?: string;
  effective_date?: string;
  status_code?: number;
  status_description?: string;
  status_date?: string;
  payoff_amt?: number;
  late_fees_amt?: number;
  late_days?: number;
  late_charge_amt?: number;
  total_premium_amt?: number;
  downpayment_amt?: number;
  financed_amt?: number;
  finance_charges_amt?: number;
  doc_stamps_amt?: number;
  cancellation_return_amt?: number;
  external_quote_id?: string;
  honor_capital_contract_id?: string;
  honor_capital_agency_id?: string;

  client?: Client;
  insured?: EndInsured;
  payments?: Array<PremiumFinancePayment>;
};
