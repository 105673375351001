import { Typography } from "@mui/material";
import { TypographyProps } from "./typography-props";

export default function Subtitle({
  children,
  bold,
  ...props
}: TypographyProps) {
  return (
    <Typography
      variant="subtitle1"
      {...props}
      sx={{
        fontWeight: bold ? 600 : 400,
        ...(props.sx || {}),
      }}
    >
      {children}
    </Typography>
  );
}
