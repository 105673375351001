import { CrudPathProps } from "routes/crud";
import { Client, Maybe, PremiumFinanceQuote } from "types";
import { clientPremiumFinancePath } from "./client-premium-finance";

export interface ClientPremiumFinanceQuotePathProps extends CrudPathProps {
  premium_finance_quote?: Maybe<PremiumFinanceQuote>;
  pf_client_id?: Maybe<string>;
}

export const clientPremiumFinanceQuotePath = (
  client: Client,
  opts?: ClientPremiumFinanceQuotePathProps
) => {
  const pf_client_id = opts?.premium_finance_quote?.id || opts?.pf_client_id;
  const prefix = clientPremiumFinancePath(client);

  if (pf_client_id) {
    return `${prefix}quotes/${pf_client_id}/`;
  } else {
    return `${prefix}quotes/`;
  }
};
