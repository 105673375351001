import {
  Carrier,
  CoverageType,
  CustomPolicyItemType,
  PaymentMethod,
} from "types";
import { DataObject } from "./data-object";

export enum ProductInsuranceType {
  personal = "personal",
  commercial = "commercial",
  benefits = "benefits",
}

export enum ProductCancellationType {
  pro_rata = "pro_rata",
  short_rate = "short_rate",
}

export enum ProductCarrierBillingType {
  invoice = "invoice",
  account_current = "account_current",
}

export enum ProductDistributionMethod {
  d2c = "d2c",
  agent = "agent",
}

export enum ProductPaymentPlan {
  pif = "pif",
  installment_direct = "installment_direct",
  installment_financed = "installment_financed",
  escrow_bill = "escrow_bill",
}

export type PartnerCommissionShares =
  | { [x: string]: any }
  | Array<{ [x: string]: any }>;

export type Product = DataObject & {
  client_id: string;
  coverage_type_id: string;
  coverage_type?: CoverageType;
  carrier_id: string;
  carrier?: Carrier;

  name: string;
  product_code: string;

  policy_fee_amt: number;
  policy_mep_pct: number;
  short_rate_pct: number;
  commission_pct: number;

  partner_billing_cycle_days: number;
  carrier_billing_cycle_days: number;

  pass_payment_processing_fee: boolean;

  insurance_type: ProductInsuranceType;
  cancellation_type: ProductCancellationType;
  carrier_billing_type: ProductCarrierBillingType;

  distribution_methods: Array<ProductDistributionMethod>;
  payment_plans: Array<ProductPaymentPlan>;
  payment_methods: Array<PaymentMethod>;

  partner_commission_shares: PartnerCommissionShares;
  custom_policy_item_types?: Array<CustomPolicyItemType>;
  custom_policy_item_types_ids?: Array<string>;
};
