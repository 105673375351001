import { Client, Maybe, Settlement } from "types";
import { clientMoneyInPath } from "./client-money-in";

interface ClientSettlementCrudPathProps {
  settlement?: Maybe<Settlement>;
  settlement_id?: string;
}

export const clientSettlementsPath = (
  client: Client,
  opts?: ClientSettlementCrudPathProps
) => {
  if (!client) {
    return "";
  }
  const prefix = clientMoneyInPath(client);
  if (opts?.settlement?.id || opts?.settlement_id) {
    return `${prefix}settlements/${opts.settlement?.id || opts.settlement_id}/`;
  } else {
    return `${prefix}settlements/`;
  }
};
