export * from "./agency";
export * from "./carrier";
export * from "./client";
export * from "./insured";
export * from "./retail-agency";
export * from "./user";

export const adminPaymentsPath = () => {
  return `/admin/payments`;
};
