import { Grid } from "@mui/material";
import { ReactNode } from "react";

interface GridItemProps {
  children?: ReactNode;
  showOverflow?: boolean;
  [x: string]: any;
}

export default function GridItem({
  children,
  showOverflow,
  ...props
}: GridItemProps) {
  return (
    <Grid item {...props} className={showOverflow ? "show-overflow" : ""}>
      {children}
    </Grid>
  );
}
